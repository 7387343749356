import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../../_panel/helpers/useDynamicValidationSchema";
import { intlRequiredMessage } from "../../../_panel/helpers/validation";
import ProgressBar from "../../../_panel/partials/ProgressBar";
import FormikSelectComponent from "../../../_panel/partials/formik/FormikSelectComponent";
import { BookingYear } from "../../../app/administrationSlice/administrationSlice";
import { RootState } from "../../../app/RootReducer";
import { shallowEqual, useSelector } from "react-redux";
import FormikDatepickerComponent from "../../../_panel/partials/formik/FormikDatepickerComponent";
import moment from "moment";
import { useBalanceUltimoReportMutation, useDataUltimoReportMutation } from "../../../app/apiService";
import Iframe from "react-iframe";
import { Spinner } from "react-bootstrap";
import saveAs from "file-saver";

const GenerateBalanceSchema = (intl: IntlShape) =>
  yup.object().shape({
    dateUltimo: yup
      .date()
      .label(intl.formatMessage({ id: "GENERATE_BALANCE_ULTIMO.END_DATE" }))
      .required(intlRequiredMessage(intl)),
  });
const GenerateBalanceUltimoCard = () => {
  const iframeRef = useRef();
  const [fileObj, setFileObj] = useState<string | null>(null);
  const [dataUltimoReport, { isLoading: isLoading2 }] = useDataUltimoReportMutation();
  const [balanceUltimoReport, { isLoading: isLoading }] = useBalanceUltimoReportMutation();
  const selectedBookingYear: BookingYear = useSelector<RootState>(
    ({ root }) => root.administration?.bookingYears,
    shallowEqual
  ) as BookingYear;
  const formRef: React.RefObject<any> = useRef();
  const validationSchema = useDynamicValidationSchema(GenerateBalanceSchema);
  const intl = useIntl();
  useEffect(() => {
    formRef?.current?.validateForm();
  }, [validationSchema]);
  useEffect(() => {
    dataUltimoReport({
      dateUltimo: moment
        .utc(selectedBookingYear.toDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      noBookingYearGiven: false,
    })
      .unwrap()
      .then((data) => {
        balanceUltimoReport({
          data
        })
          .unwrap()
          .then((response) => {
            setFileObj(response);
          });
      });
  }, [selectedBookingYear.toDate]);
  const downloadBtnClicked = () => {
    if (fileObj) {
      const currentDate = new Date();
      const niceDateFormat = `${currentDate.getMonth() + 1
        }_${currentDate.getDate()}_${currentDate.getFullYear()}_${currentDate.getHours()}_${currentDate.getMinutes()}`;
      const fileName = `balance_report_${niceDateFormat}.pdf`;
      saveAs(fileObj, fileName);
    }
  };
  const printBtnClicked = () => {
    if (fileObj) {
      window.open(fileObj, "PRINT");
    }
  };
  return (
    <Formik
      initialValues={{
        dateUltimo: moment
          .utc(selectedBookingYear.toDate)
          .subtract(1, "days")
          .toDate(),
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
      validateOnBlur={true}
      onSubmit={(values, { setSubmitting }) => {
        setFileObj(null);
        dataUltimoReport({
          dateUltimo: moment
            .utc(values.dateUltimo)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          noBookingYearGiven: false,
        })
          .unwrap()
          .then((data) => {
            balanceUltimoReport({
              data
            })
              .unwrap()
              .then((response) => {
                setFileObj(response);
              });
          });
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form className="card">
          {(isLoading || isLoading2) && <ProgressBar />}
          <div className="card-header align-items-center justify-content-between">
            <div className="d-flex align-items-baseline">
              <h3 className="card-title">
                {intl.formatMessage({
                  id: "GENERATE_BALANCE_ULTIMO.TITLE",
                })}
              </h3>
              <Field
                name="dateUltimo"
                className="ms-3"
                showMonthYearPicker={false}
                labelClassName="form-label"
                component={FormikDatepickerComponent}
                maxDate={moment
                  .utc(selectedBookingYear.toDate)
                  .subtract(1, "days")
                  .toDate()}
                onChange={() => {
                  if (!(isLoading || isLoading2) && !isSubmitting) {
                    submitForm();
                  }
                }}
                minDate={moment.utc(selectedBookingYear.fromDate).toDate()}
                placeholder={intl.formatMessage(
                  { id: "PLACEHOLDER" },
                  {
                    label: intl
                      .formatMessage({
                        id: "GENERATE_BALANCE_ULTIMO.END_DATE",
                      })
                      .toLocaleLowerCase(),
                  }
                )}
              />
            </div>
            <div>
              <button
                type="button"
                onClick={() => downloadBtnClicked()}
                disabled={isLoading || isLoading2}
                className="btn btn-primary btn-md"
              >
                {intl.formatMessage({ id: "DOWNLOAD" })}
              </button>
              <button
                type="button"
                onClick={() => printBtnClicked()}
                disabled={isLoading || isLoading2}
                className="btn btn-primary btn-md ms-2"
              >
                {intl.formatMessage({ id: "PRINT" })}
              </button>
            </div>
          </div>
          <div className="card-body pdfViewer">
            {fileObj ? (
              <Iframe
                key={"IframeAccounts"}
                url={fileObj + "#navpanes=0&statusbar=0&toolbar=0&view=FitH"}
                width="100%"
                id="myId"
                className="iframe pdfIframe"
                display="initial"
                position="relative"
              />
            ) : (
              <Spinner
                className="mx-auto spinner-border"
                animation="border"
                variant="primary"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GenerateBalanceUltimoCard;
