import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import { checkIsActive } from "../../helpers/RouterHelpers";

const Aside = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  return (
    <aside
      className="navbar navbar-vertical navbar-expand-lg"
      data-bs-theme="dark"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebar-menu"
          aria-controls="sidebar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <h1 className="navbar-brand navbar-brand-autodark">
          <a href="#">
            <img
              src="/static/sun-fog-svgrepo-com.svg"
              width={110}
              height={32}
              alt="Early Booking"
              className="navbar-brand-image"
            />
          </a>
        </h1>

        <div className="collapse navbar-collapse" id="sidebar-menu">
          <ul className="navbar-nav pt-lg-3">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-home"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Home icon SVG */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.HOME" })}
                </span>
              </a>
              <div className={`dropdown-menu ${
                checkIsActive(pathname, "/") || checkIsActive(pathname, "/homepage") || checkIsActive(pathname, "/create-opening-balance") ? "show" : ""
              }`}>
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/homepage"
                      title={intl.formatMessage({ id: "ASIDE.HOMEPAGE" })}
                    />
                    <MenuItem
                      to="/create-opening-balance"
                      title={intl.formatMessage({ id: "ASIDE.CREATE_OPENING_BALANCE" })}
                    />
                  </div>
                </div>
              </div>
            </li>
            
            <Link
              className={`dropdown-item ${
                checkIsActive(pathname, "/general-ledger/") ? "active" : ""
              }`}
              to="/general-ledger/"
            >
            </Link>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-base"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Download SVG icon from http://tabler-icons.io/i/package */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
                    <path d="M12 12l8 -4.5" />
                    <path d="M12 12l0 9" />
                    <path d="M12 12l-8 -4.5" />
                    <path d="M16 5.25l-8 4.5" />
                  </svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.INVOICE" })}
                </span>
              </a>
              <div
                className={`dropdown-menu ${
                  checkIsActive(pathname, "/general-ledger/") ? "show" : ""
                }`}
              >
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/general-ledger/booking"
                      title={intl.formatMessage({ id: "ASIDE.BOOKINGS" })}
                    />
                    <MenuItem
                      to="/general-ledger/diaries"
                      title={intl.formatMessage({ id: "ASIDE.DIARIES" })}
                    />
                    <MenuItem
                      to="/general-ledger/chart-of-accounts"
                      title={intl.formatMessage({
                        id: "ASIDE.CHART_OF_ACCOUNTS",
                      })}
                    />
                    <MenuItem
                      to="/general-ledger/mastertables"
                      title={intl.formatMessage({
                        id: "ASIDE.MASTERTABLES",
                      })}
                    />
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-base"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Download SVG icon from http://tabler-icons.io/i/checkbox */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 11l3 3l8 -8" />
                    <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                  </svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.OVERVIEWS" })}
                </span>
              </a>
              <div
                className={`dropdown-menu ${
                  checkIsActive(pathname, "/overviews/") ? "show" : ""
                }`}
              >
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/overviews/master-tables"
                      title={intl.formatMessage({
                        id: "ASIDE.REPORT_STRUCTURE",
                      })}
                    />
                  </div>
                </div>
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/overviews/reports"
                      title={intl.formatMessage({
                        id: "OVERVIEWS.DOWNLOAD_BOOKINGS",
                      })}
                    />
                  </div>
                </div>
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column">
                    <MenuItem
                      to="/overviews/generate-balance-ultimo"
                      title={intl.formatMessage({
                        id: "OVERVIEWS.GENERATE_BALANCE_ULTIMO",
                      })}
                    />
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* Download SVG icon from http://tabler-icons.io/i/checkbox */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                    <path d="M15 15l3.35 3.35" />
                    <path d="M9 15l-3.35 3.35" />
                    <path d="M5.65 5.65l3.35 3.35" />
                    <path d="M18.35 5.65l-3.35 3.35" />
                  </svg>
                </span>
                <span className="nav-link-title">
                  {intl.formatMessage({ id: "ASIDE.HELP" })}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Aside;
