import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik, FormikProps } from "formik";
import { IntlShape, useIntl } from "react-intl";
import * as yup from "yup";
import { useDynamicValidationSchema } from "../../_panel/helpers/useDynamicValidationSchema";
import { intlRequiredMessage } from "../../_panel/helpers/validation";
import ProgressBar from "../../_panel/partials/ProgressBar";
import FormikSelectComponent from "../../_panel/partials/formik/FormikSelectComponent";
import { BookingYear } from "../../app/administrationSlice/administrationSlice";
import { RootState } from "../../app/RootReducer";
import { useSelector } from "react-redux";
import moment from "moment";
import { useAddNewBookingMutation, useDataUltimoReportMutation, useBalanceUltimoReportMutation } from "../../app/apiService";
import Iframe from "react-iframe";
import { Spinner } from "react-bootstrap";
import saveAs from "file-saver";
import { useFetchAllBookingYearsQuery } from "../../app/apiService";
import { toast } from "react-toastify";

const GenerateOpeningBalance: React.FC = () => {
  const intl = useIntl();
  const [fileObj, setFileObj] = useState<string | null>(null);
  const [bb, setBb] = useState<Record<string, any>>({});
  let isBookingLoading = false;
  const [dataUltimoReport, { isLoading: isLoading2 }] = useDataUltimoReportMutation();
  const [balanceUltimoReport, { isLoading: isLoading }] = useBalanceUltimoReportMutation();
  const [addNewBooking, { isLoading: isLoading3 }] = useAddNewBookingMutation();
  const bookingYears = useSelector((state: RootState) => {
    const { administration } = state.root;
    return administration?.bookingYears ?? null;
  });

  const companyId = bookingYears?.companyId ?? 0;
  const { data: allBookingYears = [] } = useFetchAllBookingYearsQuery(companyId);

  const formRef = useRef<FormikProps<{ bookingYear: BookingYear | null }> | null>(null);

  const validationSchema = yup.object().shape({
    bookingYear: yup.object().nullable().required(intlRequiredMessage(intl)),
  });

  const defaultBookingYear = React.useMemo(() => {
    const lastYear = bookingYears?.fromDate ? moment(bookingYears.fromDate).year() - 1 : undefined;
    return lastYear ? allBookingYears.find(year =>
      moment(year.fromDate).year() === lastYear || moment(year.toDate).year() === lastYear
    ) : null;
  }, [bookingYears, allBookingYears]);

  useEffect(() => {
    if (defaultBookingYear && formRef.current) {
      formRef.current.setFieldValue('bookingYear', defaultBookingYear);
      formRef.current.submitForm();
    }
  }, [defaultBookingYear]);
  useEffect(() => {
    if (defaultBookingYear?.toDate) {
      dataUltimoReport({
        dateUltimo: moment
          .utc(defaultBookingYear.toDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        noBookingYearGiven: true,
      })
        .unwrap()
        .then((data) => {
          setBb(data);
          balanceUltimoReport({
            data
          })
            .unwrap()
            .then((response) => {
              setFileObj(response);
            });
        });
    }
  }, [defaultBookingYear?.toDate]);
  const BrowseBtnClicked = () => {
    if (fileObj) {
      const currentDate = new Date();
      const niceDateFormat = `${currentDate.getMonth() + 1
        }_${currentDate.getDate()}_${currentDate.getFullYear()}_${currentDate.getHours()}_${currentDate.getMinutes()}`;
      const fileName = `opening_balance_${niceDateFormat}.pdf`;
      saveAs(fileObj, fileName);
    }
  };

  const CreateOpeningBalanceBtnClicked = () => {
    isBookingLoading = true;
    console.log("bb", bb);
    if (Object.keys(bb.accounts).length > 0) {
      for (let i=0;i<bb.accounts.length;i++) {
        const booking = bb.accounts[i];
        const values = {
          diaryNo: "1",
          companyNo: bb.companyNo,
          bookingYearsNo: bb.bookingYearsNo,
          date: moment(defaultBookingYear?.fromDate).add(1, 'year').format("yyyy-MM-DD"),
          description: booking.description+" Opening Balance",
          projectNumber: "0",
          accountNo: booking.account,
          amountCredit: booking.credit,
          amountDebet: booking.debet,
          bookingHeaderId: null
        };
        if (!values.diaryNo) {
          toast.warn(intl.formatMessage({ id: "BOOKING.SELECT_DIARY_WARNING" }));
          isBookingLoading = false;
          return;
        }

        addNewBooking({ ...values })
          .unwrap()
          .then(() => {
            isBookingLoading = true;
            toast.success(intl.formatMessage({ id: "BOOKING.CREATED" }));
          })
          .catch((err: any) => {
            isBookingLoading = false;
            if (err.data?.code === 1101) {
              toast.error(
                intl.formatMessage({ id: "BOOKING.CREATED_FAILED_ACCOUNT" })
              );
            } else {
              toast.error(intl.formatMessage({ id: "BOOKING.CREATED_FAILED" }));
            }
          });
      };
    } else {
      console.error("bb is empty");
      isBookingLoading = false;
    }
  };


  return (
    <Formik
      initialValues={{
        bookingYear: null as BookingYear | null, // Explicitly type this
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
      onSubmit={(values, { setSubmitting }) => {

        console.log("values", values);
        console.log("values.bookingYear", values.bookingYear);

        if (values.bookingYear) {
          const selectedYear = values.bookingYear;
          const previousYearEnd = moment(selectedYear.fromDate).subtract(1, 'day').format('YYYY-MM-DD');

          console.log("Submitting form with date:", previousYearEnd);  // Add this line

          dataUltimoReport({
            dateUltimo: previousYearEnd,
            noBookingYearGiven: true,
          })
            .unwrap()
            .then((data) => {
              setBb(data);
              balanceUltimoReport({
                data
              })
                .unwrap()
                .then((response) => {
                  setFileObj(response);
                });
            })
            .catch((error) => {
              console.error("Error generating report:", error);
            });
        } else {
          console.error("No booking year selected");
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm, values }) => (
        <Form className="card">
          {isLoading && <ProgressBar />}
          <div className="card-header align-items-center justify-content-between">
            <div className="d-flex align-items-baseline">
              <div>
                <button
                  type="button"
                  onClick={CreateOpeningBalanceBtnClicked}
                  disabled={isLoading || !fileObj || isBookingLoading}
                  className="btn btn-primary btn-md"
                >
                  {intl.formatMessage({ id: "GENERATE_OPENING_BALANCE.TITLE" })}
                </button>
              </div>
              <Field
                name="bookingYear"
                className="ms-3"
                labelClassName="form-label"
                component={FormikSelectComponent}
                options={allBookingYears.map(year => ({
                  value: year,
                  label: `${moment(year.fromDate).format('DD-MM-YYYY')} - ${moment(year.toDate).format('DD-MM-YYYY')}`
                }))}
                onChange={(selectedOption: any) => {
                  if (!isLoading && !isSubmitting) {
                    submitForm();
                  }
                }}
                placeholder={intl.formatMessage({ id: "GENERATE_OPENING_BALANCE.BOOKING_YEAR" })}
              />
            </div>
            <div>
              <button
                type="button"
                onClick={BrowseBtnClicked}
                disabled={isLoading || !fileObj || isBookingLoading}
                className="btn btn-primary btn-md"
              >
                {intl.formatMessage({ id: "SHOW" })}
              </button>
            </div>
          </div>
          <div className="card-body pdfViewer">
            {isLoading || isBookingLoading ? (
              <Spinner
                className="mx-auto spinner-border"
                animation="border"
                variant="primary"
              />
            ) : fileObj ? (
              <Iframe
                url={fileObj + "#navpanes=0&statusbar=0&toolbar=0&view=FitH"}
                width="100%"
                height="600px"
                id="myId"
                className="iframe pdfIframe"
                display="initial"
                position="relative"
              />
            ) : (
              <p>No PDF generated yet. Please select a booking year.</p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GenerateOpeningBalance;
