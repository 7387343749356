import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FallbackMainView } from "../_panel/partials/FallbackMainView";
import BookingPage from "../pages/BookingPage";
import ChartAccountPage from "../pages/ChartAccountPage";
import DiariesPage from "../pages/DiariesPage";
import HomePage from "../pages/HomePage";
import MasterTablesPage from "../pages/MasterTablesPage";
import OverviewsPage from "../pages/OverviewsPage";
import { ProtectedRoute } from "./ProtectedRoute";
import GenerateBalanceUltimoPage from "../pages/GenerateBalanceUltimoPage";
import OpeningBalancePage from "../pages/OpeningBalancePage";

const PrivateRoutes = () => {
  return (
    <Suspense fallback={<FallbackMainView />}>
      <Routes>
      <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-opening-balance/*"
          element={
            <ProtectedRoute>
              <OpeningBalancePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/general-ledger/booking/*"
          element={
            <ProtectedRoute>
              <BookingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/general-ledger/chart-of-accounts/*"
          element={
            <ProtectedRoute>
              <ChartAccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/general-ledger/mastertables/*"
          element={
            <ProtectedRoute>
              <MasterTablesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/general-ledger/diaries/*"
          element={
            <ProtectedRoute>
              <DiariesPage />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/master-tables/account-types/*"
          element={
            <ProtectedRoute>
              <AccountTypesPage />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/overviews/master-tables/*"
          element={
            <ProtectedRoute>
              <MasterTablesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/overviews/reports/*"
          element={
            <ProtectedRoute>
              <OverviewsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/overviews/generate-balance-ultimo/*"
          element={
            <ProtectedRoute>
              <GenerateBalanceUltimoPage />
            </ProtectedRoute>
          }
        />
        {/* <Navigate exact from='/' to='/product' /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};
export default PrivateRoutes;
